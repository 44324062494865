import "./transformators"
import "./FeatureChecker"
import "@Environment/Loaders"
import "@App/loaders"

import "./swLoader"
import "./domModules"
import "./Language"

import "./init"
